import memoize from 'memoize-one';
import React from 'react';
import ReactWebChat, {createDirectLine, createStyleSet } from 'botframework-webchat';

import './styles/WebChat.css';

export default class extends React.Component {
   constructor(props) {
      super(props);
      this.createDirectLine = memoize(token => createDirectLine(window.directline));
      this.state = {
         styleSet: createStyleSet({
            fontFamily: 'Roboto,sans-serif',
            rootHeight: '100%',
            rootWidth: '100%',
            sendBoxButtonColor: '#01a5e8',
            backgroundColor: '#fff',
            hideUploadButton: true
         }),
         options: {
         botAvatarImage: 'https://i.imgur.com/ywz1Ef2.png',
         botAvatarInitials: 'WC',
         userAvatarImage: 'https://i.imgur.com/po2Oj1V.jpg',
         userAvatarInitials: 'WC',
         hideUploadButton: true,
         groupTimestamp: undefined,
         emojiSet: {}
         }
      };
   }
   render() {
      const {
         props: { className, store },
         state: { styleSet, options }
      } = this;
      return !this.state.directLine ? (
         <ReactWebChat
         className={`${className || ''} web-chat`}
         directLine={this.createDirectLine(window.directline)}
         store={store}
         styleSet={styleSet}
         styleOptions={options}
         locale="en-us"
      />
      ) :
      (
         <div className={`${className || ''} connect-spinner`}>
            <div className="content">
               <div className="icon">
                  <span className="ms-Icon ms-Icon--Robot" />
               </div>
               <p>Please wait while we are connecting.</p>
            </div>
         </div>
      )
   }
}