import React from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';

import WebChat from './WebChat';

import './styles/fabric-icons-inline.css';
import './styles/MinimizableWebChat.css';

export default class extends React.Component {
   constructor(props) {
      super(props);

      this.handleMaximizeButtonClick = this.handleMaximizeButtonClick.bind(this);
      this.handleMinimizeButtonClick = this.handleMinimizeButtonClick.bind(this);

      setTimeout(() => {
         this.setState(() => ({
            loaded: true,
            minimizable: false,
            newMessage: false,
            hidden: false
         }));
      }, 100)

      const store = createStore({}, ({ dispatch }) => next => action => {
         if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
            if (action.payload.activity.from.role === 'bot') {
               this.setState(() => ({ newMessage: true }));
            }
         }
         return next(action);
      });

      this.state = {
         minimizable: true,
         newMessage: false,
         store,
         styleSet: createStyleSet({
            backgroundColor: 'Transparent'
         }),
         conversationId: null,
         loaded: false,
         hidden: false
      };
   }
   handleMaximizeButtonClick() {
      this.setState(() => ({
         loaded: true,
         minimizable: false,
         newMessage: false,
         hidden: false
      }));
   }

   handleMinimizeButtonClick() {
      this.setState(() => ({
         minimizable: true,
         newMessage: false,
         hidden: true
      }));
   }


   render() {
      const {
         state: { minimizable, newMessage, store, styleSet, loaded, hidden}
      } = this;
      
      return (
         <div className="minimizable-web-chat">
            {minimizable && (
               <button className="maximize" onClick={this.handleMaximizeButtonClick}>
                  <span className="ms-Icon ms-Icon--Message" />
                  {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
               </button>
            )}
           {loaded &&
               <div className={hidden === true ? 'chat-box right hide' : 'chat-box right'}>
               <header>
                  <div className="filler" />
                  <button className="minimize" onClick={this.handleMinimizeButtonClick}>
                     <span className="ms-Icon ms-Icon--ChromeMinimize" />
                  </button>
               </header>
               <WebChat
                  className="react-web-chat chat-box"
                  store={store}
                  styleSet={styleSet}
               />
            </div>
           }
         </div>
      );
   }
}