import React, { Component } from 'react';
import MinimizableWebChat from './MinimizableWebChat';

import './styles/App.css';

class App extends Component {
  render() {
    return (
     <div className="AppWebChat">
       <MinimizableWebChat />
     </div>
   );
  }
}

export default App;